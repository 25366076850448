import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {Menu} from "@app/shell/models";
import {AuthFacade} from "@app/core/facades/auth.facade";
import {Subscription} from "rxjs";
import {User} from "@app/core/models/user";
import {Alert} from "@app/core/models/alert";
import {AlertsFacade} from "@app/core/facades/alerts.facade";
import {BadgeModule} from "@progress/kendo-angular-indicators";
import {TranslateModule} from "@ngx-translate/core";
import {UserPermissionCodeEnum} from "@app/core/enums/user-permission-code.enum";
import {TooltipModule} from "@progress/kendo-angular-tooltip";
import {CanDoActionDirective} from "@app/core/directives/can-do-action.directive";
import {AvatarModule} from '@progress/kendo-angular-layout';
import {InitialsPipe} from '@app/shared/pipes/initials.pipe';

@Component({
  selector: '[app-sidebar]',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, BadgeModule, TranslateModule, TooltipModule, CanDoActionDirective, AvatarModule, InitialsPipe],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public user!: User | null;
  public alerts!: Alert[] | null;
  public menu: Menu[] = [];
  private _subscription: Subscription = new Subscription();

  constructor(
    private _authFacade: AuthFacade,
    private _alertsFacade: AlertsFacade
  ) {
    this._setMenu();
    this._subscription.add(this._authFacade.user$.subscribe((user) => {
      this.user = user;
    }));
  }

  ngOnInit(): void {
    this._subscription.add(this._alertsFacade.alerts$.subscribe((alerts) => this.alerts = alerts));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public onCloseBtnClick() {
    let sidebar = document.querySelector(".sidebar");

    sidebar?.classList?.toggle("open");
  }

  public logout() {
    this._authFacade.logout();
  }

  private _setMenu() {
    this.menu.push({
      name: 'PROFILES.TITLE',
      icon: 'ico-profiles',
      url: '/profiles',
      permissions: [UserPermissionCodeEnum.VIEW_PROFILE_LIST]
    });
    this.menu.push({
      name: 'ML_MODELS.TITLE',
      icon: 'ico-mlmodels',
      url: '/ml-models',
      permissions: [UserPermissionCodeEnum.VIEW_ML_MODEL_LIST]
    });
    this.menu.push({
      name: 'WISHLISTS.TITLE',
      icon: 'ico-wishlist',
      url: '/wishlists',
      permissions: [UserPermissionCodeEnum.VIEW_WISHLIST_LIST]
    });
    this.menu.push({
      name: 'FLIGHT_DEMAND.TITLE',
      icon: 'ico-flight-demand',
      url: '/flight-demands',
      permissions: [UserPermissionCodeEnum.VIEW_FLIGHT_DEMAND_LIST]
    });
    this.menu.push({
      name: 'EVALUATIONS.TITLE',
      icon: 'ico-evaluation',
      url: '/evaluations',
      permissions: [UserPermissionCodeEnum.VIEW_EVALUATION_LIST]
    });
    this.menu.push({
      name: 'EVALUATIONS_COMPARISON.TITLE',
      icon: 'ico-evaluations-result',
      url: '/evaluations-comparison',
      permissions: [UserPermissionCodeEnum.VIEW_EVALUATION_RESULT]
    });
    this.menu.push({
      name: 'KPIS.TITLE',
      icon: 'ico-kpi',
      url: '/kpis',
      permissions: [UserPermissionCodeEnum.VIEW_KPI_LIST]
    });
    this.menu.push({
      name: 'OPTIMISATIONS.TITLE',
      icon: 'ico-optimisation',
      url: '/optimisations',
      permissions: [UserPermissionCodeEnum.VIEW_OPTIMISATION_LIST]
    });
    this.menu.push({
      name: 'USERS.USERS_LIST',
      icon: 'ico-users',
      url: '/users',
      permissions: [UserPermissionCodeEnum.VIEW_USER]
    });
  }

}
