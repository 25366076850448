import {Routes} from '@angular/router';
import {UserResolver} from '@app/core/resolvers/user.resolver';
import {AuthGuard} from '@app/core/guards/auth.guard';
import {ForbiddenPageComponent} from '@app/shared/components/forbidden-page/forbidden-page.component';
import {ShellComponent} from '@app/shell/components/shell/shell.component';
import {passwordChangeRequiredGuard} from '@app/core/guards/password-change-required.guard';
import {NotFoundPageComponent} from '@shared/components/not-found-page/not-found-page.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard'
  },
  {
    path: 'unauthorized',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: '403',
    canActivate: [AuthGuard],
    resolve: {user: UserResolver},
    component: ForbiddenPageComponent
  },
  {
    path: '404',
    component: NotFoundPageComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {user: UserResolver},
    component: ShellComponent,
    children: [
      {
        path: '',
        canMatch: [passwordChangeRequiredGuard],
        children: [
          {
            path: 'users',
            loadChildren: () => import('@app/users/users.routes').then(m => m.routes)
          },
          {
            path: 'dashboard',
            loadChildren: () => import('@app/dashboard/dashboard.routes').then(m => m.routes)
          },
          {
            path: 'optimisations',
            loadChildren: () => import('@optimisations/optimisations.routes').then(m => m.routes)
          },
          {
            path: 'profile',
            loadChildren: () => import('@app/profile/profile.routes').then(m => m.routes)
          },
          {
            path: 'profiles',
            loadChildren: () => import('@app/profiles/profiles.routes').then(m => m.routes)
          },
          {
            path: 'kpis',
            loadChildren: () => import('@kpis/kpis.routes').then(m => m.routes)
          },
          {
            path: 'ml-models',
            loadChildren: () => import('@ml-models/ml-models.routes').then(m => m.routes)
          },
          {
            path: 'wishlists',
            loadChildren: () => import('@wishlists/wishlists.routes').then(m => m.routes)
          },
          {
            path: 'evaluations',
            loadChildren: () => import('@evaluations/evaluations.routes').then(m => m.routes)
          },
          {
            path: 'evaluations-comparison',
            loadChildren: () => import('@app/evaluations-comparison/evaluations-comparison.routes').then(m => m.routes)
          },
          {
            path: 'evaluations-result',
            loadChildren: () => import('@app/evaluation-results/evaluations-result.routes').then(m => m.routes)
          },
          {
            path: 'flight-demands',
            loadChildren: () => import('@app/flight-demands/flight-demand.routes').then(m => m.routes)
          },
          {
            path: 'release-notes',
            loadChildren: () => import('@app/settings/settings.routes').then(m => m.routes)
          },
        ]
      },
      {
        path: 'change-password',
        loadComponent: () => import('@app/profile/components/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/404'
  },
];
